import $ from 'jquery';
import 'slick-carousel';
import 'bootstrap';
import {
  TweenMax,
  TimelineLite,
  Power2,
  Elastic,
  CSSPlugin,
} from 'gsap/TweenMax';

$(() => {
  $('.pagetop').on('click', (e) => {
    e.preventDefault();
    $('html,body').animate({
      scrollTop: 0,
    }, 700);
  });
  
  $('header a.yoko').on('click', () => {
    // 表示・非表示切り替え
    $('subnav').toggle();
  });
  
  $('.parent .nav-link').on('click', () => {
    $('#submenu').slideToggle(); // サブメニューを表示
    return false;
  });
  
  $('form#otoiawase button.submit').on('click', () => {
    $('#loading').html('<i class=\'fa fa-spinner fa-fw fa-spin\'></i>').fadeIn();
    
    let name = $('input#name').val();
    let tel = $('input#tel').val();
    let email = $('input#email').val();
    let content = $('textarea#content').val();
    let data = `name=${name}&tel=${tel}&email=${email}&content=${content}`;
    
    $.ajax({
      type: 'POST',
      url: '../assets/inc/sendEmail.php',
      data,
      success(msg) {
        // Message was sent
        if (msg === 'OK') {
          $('#message-warning').hide();
          $('#message-success').fadeIn();
          $('.submit').attr('disabled', true);
        }
        // There was an error
        else {
          $('#message-warning').html(msg);
          $('#message-warning').fadeIn();
        }
      },
      complete() {
        $('#loading').empty();
      },
      
    });
    return false;
  });
});
